import React from 'react'
import {Link} from 'react-router-dom';
import daruzoPink from '../images/daruzo-pink-square.png'
import Footer from '../components/Footer'
const Policy = () => {
  return (
    <div style={{position: "realtive", color: 'white',width:"100%", height:"100%", background: "#08070D"}}>
     <div style={{textAlign: "center",  backgroundColor: "#08070D"}}>
        <Link to='/'>
        <img src={daruzoPink} alt="daruzo" style={{height: "125px"}}/>  
        </Link>
      </div>
    <h1 style={{textAlign: "center"}}>Privacy Policy</h1>

    <div style={{margin: '0 15rem', width: "80%"}}>

    <p>
    This privacy policy ("policy") will help you understand how Daruzo ("us", "we", "our") uses and
  protects the data you provide to us when you visit and use daruzo.com ("website", "service").
    </p>
    <p>
  We reserve the right to change this policy at any given time, of which you will be promptly
  updated. If you want to make sure that you are up to date with the latest changes, we advise
  you to frequently visit this page

    </p>
    <div>
      <h3> What User Data We Collect </h3>
      <ul>
        <li>Your IP address.</li>
        <li>Your email address.</li>
        <li>Other information such as interests and preferences.</li>
        <li>Data profile regarding your online behavior on our website</li>
      </ul>
    </div>
      
    <div>
      <h3> Why We Collect Your Data </h3>
      <ul>
        <li>To better understand your needs.</li>
        <li>To improve our services</li>
        <li>To send you promotional emails containing the information we think you will find interesting.</li>
        <li>To customize our website according to your online behavior and personal preferences.</li>
      </ul>
    </div>

    <div>
      <h3>Safeguarding and Securing the Data</h3>
      <ul>
      Daruzo is committed to securing your data and keeping it confidential. Daruzo has done all in its
      power to prevent data theft, unauthorized access, and disclosure by implementing the latest
      technologies and software, which help us safeguard all the information we collect online.
      </ul>
    </div>

    <div>
      <h3>Our Cookie Policy</h3>
      <h5>No Cookies are being used atm-Email will be sent when implemented</h5>
      <ul>
        <p>
        Once you agree to allow our website to use cookies, you also agree to use the data it collects
        regarding your online behavior (analyze web traffic, web pages you spend the most time on,
        and websites you visit).

        </p>
        <p>
          The data we collect by using cookies is used to customize our website to your needs. After we
          use the data for statistical analysis, the data is completely removed from our systems.

        </p>
        <p>
          Please note that cookies don't allow us to gain control of your computer in any way. They are
          strictly used to monitor which pages you find useful and which you do not so that we can
          provide a better experience for you.

        </p>
      </ul>
    </div>

    <div>
      <h3>Links to Other Websites</h3>
      <ul>
        Our website contains links that lead to other websites. If you click on these links Daruzo is not
        held responsible for your data and privacy protection. Visiting those websites is not governed by
        this privacy policy agreement. Make sure to read the privacy policy documentation of the
        website you go to from our website
      </ul>
    </div>
    <div>
      <h3>
        Restricting the Collection of your Personal Data
      </h3>
      <ul>
        <p>
        At some point, you might wish to restrict the use and collection of your personal data. You can
achieve this by doing the following:

        </p>
        <p>
        When you are filling the forms on the website, make sure to check if there is a box which you
can leave unchecked, if you don't want to disclose your personal information.
        </p>
        <p>
        If you have already agreed to share your information with us, feel free to contact us via email
and we will be more than happy to change this for you.
        </p>
        <p>
        Daruzo will not lease, sell or distribute your personal information to any third parties, unless we
have your permission. We might do so if the law forces us. Your personal information will be
used when we need to send you promotional materials if you agree to this privacy policy.

        </p>
      </ul>
    </div>
    </div>
    {/* <Footer /> */}
  </div>
  )
}

export default Policy
