import React, {  useState, useEffect } from "react";
import { Link } from 'react-router-dom'
//Material UI
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid"

//Components
import AlertSuccess from "../components/AlertSuccess";
import Footer from '../components/Footer'
// temp logo
import logo from '../images/daruzo.png'

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    position: "absolute",
    // backgroundColor: "#000000",
    display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    flewGrow: 1,
    fontFamily: "prototype"
  },
  innerRoot: {
    // width: "98%",
    // height: "98%",
    // marginLeft: "auto",
    // marginRight: "auto",
    // backgroundColor: "#ffffff",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontFamily: "prototype"
  },
  leftBox: {
    // marginLeft: "auto",
    // marginRight: "auto",
    // width: "80%",
    height: "600px",
    backgroundColor: "#e2ad28",
    // position: "relative",
    // borderRadius: 10,
    zIndex: 5,
    overflowY: "hidden",
    color: "dark grey",
    maxWidth: 750,
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "prototype"
    // justifyContent: "space-between"
  },
  title:{
    // fontFamily: "montserrat", 
    marginLeft: 30, 
    marginTop: 35, 
    fontSize: 32,
    // fontSize: "clamp(16px, 3vw, 24px)",
    margin: "0 auto",
    fontFamily: "prototype"
  },
  rightBox: {
    fontFamily: "prototype",
    // marginLeft: "auto",
    // marginRight: "auto",
    // width: "80%",
    height: "600px",
    backgroundColor: "white",
    position: "relative",
    // borderRadius: 10,
    zIndex: 5,
    overflowY: "hidden",
    // color: "#ffffff",
    maxWidth: 750,
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between"
  },
  textF: {
    width: "100%",
    textAlign: "left",
    color: "#000000",
    fontFamily: "prototype",
    "& label.Mui-focused": {
      color: "#000000",
      fontFamily: "prototype"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
        fontFamily: "prototype"
      },
      "&:hover fieldset": {
        borderColor: "black",
        fontFamily: "prototype"
      },
      "&.Mui-focused fieldset": {
        borderColor: "black",
        fontFamily: "prototype"
      },
    },
  },
  logo:{
    // background: "white",
    margin: "0 auto",
    // width: "30%", 
    marginTop: 0, 
    width: 192, 
    height: 192
  },
  multilineColor: {
    color: "#000000",
  },
}));

const EmailList = () => {
  const SmallScreen = useMedia("(min-width: 756px)");
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [alert, setAlert] = useState(false);
  const [finished, setFinished] = useState(false);



  const handleEmail = () => {
    if (email === "" || emailIsValid === false) {
      setEmailError(true);
      return;
    }

    setEmailError(false);

    setAlert(true);

    setTimeout(
      function () {
        setAlert(false);
      }.bind(this),
      4000
    );

    //OLD FB CALL
    //db.collection("emails").add(formData);

    setFinished(true);
    const data = { email: email };
  // Our Email
  //https://us-central1-daruzo.cloudfunctions.net/api/
  //http://localhost:5000/daruzo/us-central1/api
  fetch('https://us-central1-daruzo.cloudfunctions.net/api/maillist', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
        
      
  };

  const emailIsValid = () => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  return (
    <>
        <Grid container justify="center" className={classes.root} spacing={0}>
      {alert ? <AlertSuccess /> : null}
      <Grid item className={classes.innerRoot}>
        
        <div className={classes.leftBox}>
          {/* <img src={daruzo_logo} alt="naoux" style={{width: "30%", marginLeft: "auto", marginRight: "auto", marginTop: 35, minWidth: 140, maxWidth: 190}}/> */}
            <img src={logo}  className={classes.logo} alt="logo" />
            <p className={classes.title}>
              Built by gamers, for gamers.
            </p>
          <div>
          </div>
            <div>
              <p
                style={{
                  fontSize: 18,
                  // fontFamily: "montserrat",
                  marginLeft: 30,
                  marginRight: 30,
                  marginTop: 10,
                  marginBottom: 10
                }}
              >
              Daruzo is going to change the way gamers buy their peripheral and accessories. You will never have to search around for hours trying to find the perfect headset, mouse, keyboard etc. We understand the pain of trying to find that perfect headset, but not knowing where to start. We plan to fix that. We plan to be more than a consumer hub; we want to be a community of like-minded people. A place where gamers can talk about the new and best products on the market. A place where gamers can be gamers
              </p>
            </div>

    {finished ? ( 
        <div>
          <h2 style={{ textAlign: 'center', margin: 30, marginTop: 20, fontSize: "clamp(16px, 3vw, 24px)" }}>
          Thank you for for joining the daruzo team!
          </h2>
        </div>
           ) : <div>
          {/* {emailError ? (
            <div>
              <p style={{ marginLeft: 30, color: "red", marginBottom: 5 }}>
                *Not a valid email.
              </p>
            </div>
          ) : (
            <div>
              <p> </p>
            </div>
          )} */}
          {/* {SmallScreen ? (
            <div
              style={{
                display: "flex",
                justifyContent: 'space-between',
              }}
            >
            </div>
          ) : (
            <div>
              <TextField
                id="filled-basic"
                autoComplete="off"
                label="Email"
                variant="outlined"
                style={{ width: "80%", marginLeft: 30 }}
                InputLabelProps={{ classes: { root: classes.textF } }}
                InputProps={{ className: classes.multilineColor }}
                className={classes.textF}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                variant="contained"
                onClick={handleEmail}
                style={{
                  backgroundColor: "#ffffff",
                  width: "80%",
                  color: "#000000",
                  marginLeft: 30,
                  minHeight: 44,
                  marginTop: 20,
                  marginBottom: 0
                }}
              >
                Subscribe
              </Button>
            </div>
          )} */}
          <p
            style={{
              fontSize: 16,
              fontFamily: "montserrat",
              marginLeft: 30,
              marginTop: 10,
              marginBottom: 15,
              maxWidth: 600,
            }}
          >
            Never spam. No personal information, ever.
          </p>
          </div>}
        </div>
      </Grid>
      <Grid item className={classes.innerRoot}>
      <div className={classes.rightBox}>
           
          <div >
          <h1 style={{ marginLeft: 30, marginTop: 35, fontSize: "clamp(16px, 3vw, 24px)" }}>
          Register for Updates
          </h1>
          </div>
            <div>
              <p
                style={{
                  fontSize: "16px",
                  marginLeft: 30,
                  marginRight: 30,
                  marginTop: 10,
                  marginBottom: 10
                }}
              >
                We will only email when there is an update to our site
              </p>
            </div>

    {finished ? ( 
        <div>
          <h2 style={{ textAlign: 'center', margin: 30, marginTop: 20, fontSize: "clamp(16px, 3vw, 24px)" }}>
          Thank you for for joining the daruzo team!
          </h2>
        </div>
           ) : <div>
          {emailError ? (
            <div>
              <p style={{ marginLeft: 30, color: "red", marginBottom: 5 }}>
                *Not a valid email.
              </p>
            </div>
          ) : (
            <div>
              <p> </p>
            </div>
          )}
          {SmallScreen ? (
            <div
              style={{
                display: "flex",
                justifyContent: 'space-between',
                marginTop: 75
              }}
            >
              <TextField
                id="email"
                autoFocus
                required
                autoComplete="email"
                label="Email"
                variant="outlined"
                style={{ flexGrow: 1, marginLeft: 30 }}
                InputLabelProps={{ classes: { root: classes.textF } }}
                InputProps={{ className: classes.multilineColor }}
                className={classes.textF}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <Button
                variant="contained"
                onClick={handleEmail}
                style={{
                  backgroundColor: "#000000",
                  width: 200,
                  color: "#ffffff",
                  marginRight: 30,
                  marginLeft: 30,
                  marginBottom: 0,
                  fontFamily: "prototype"
                }}
              >
                Subscribe
              </Button>
            </div>
          ) : (
            <div style={{marginTop: 75}}>
              <TextField
                id="email"
                autoFocus
                required
                autoComplete="email"
                label="Email"
                variant="outlined"
                style={{ width: "80%", marginLeft: 30 }}
                InputLabelProps={{ classes: { root: classes.textF } }}
                InputProps={{ className: classes.multilineColor }}
                className={classes.textF}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                variant="contained"
                onClick={handleEmail}
                style={{
                  backgroundColor: "#ffffff",
                  width: "80%",
                  color: "#000000",
                  marginLeft: 30,
                  minHeight: 44,
                  marginTop: 20,
                  marginBottom: 0,
                  fontFamily: "prototype"
                }}
              >
                Subscribe
              </Button>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "space-evenly"}}>
          <p
            style={{
              fontSize: 14,
              // fontFamily: "montserrat",
              marginLeft: 30,
              marginTop: 10,
              marginBottom: 15,
              maxWidth: 600,
            }}
          >
           <Link style={{color: "black" }} to='/terms'>
            Terms and Conditions
           </Link>
          
          </p>
          <p   
          style={{
              fontSize: 14,
              // fontFamily: "montserrat",
              marginLeft: 30,
              marginTop: 10,
              marginBottom: 15,
              maxWidth: 600,
            }}>
              <Link style={{color: "black" }} to='/policy'>
                Privacy and Policy
              </Link>
          </p>
          </div>
         
    <Footer/> 
          </div>}
        </div>
      </Grid> 
    </Grid>
    </>
  );
}

function useMedia(query) {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => setMatches(media.matches);
    media.addListener(listener);

    return () => media.removeListener(listener);
  }, [query]);

  return matches;
}


export default EmailList