import React from 'react';
// import logo from './logo.svg';
import './App.css';
import EmailList from './pages/EmailList';
// import bg from './images/gaming-setup-bg.jpg'
import { Route, Switch } from "react-router-dom";
import TermsConditions from './pages/TermsConditions';
import Policy from './pages/Policy';
// import Footer from './components/Footer';
const App = () => {
  return (
    // <div style={{background: `url(${bg}) no-repeat center center fixed`,  height: '100vh', backgroundSize: 'cover'}}>
    <div style={{height: '100%', minHeight: "100vh",  background: "#08070D"}}> 
        <Switch>
          <Route exact path="/" component={EmailList}/>
          <Route exact path="/policy" component={Policy}/>
          <Route exact path="/terms" component={TermsConditions}/>
        </Switch>

    </div>
    // <div style={{background: `url(${bg}) no-repeat center center fixed`,  height: '100vh', backgroundSize: 'cover'}}>
    //     <EmailList/>
    // </div.>

    
  );
}

export default App;
